<template>
    <div>
      <v-card :elevation="1" class="v-sheet theme--light br-0">
        <v-card-title>
          <v-row class="col-md-12">
            <v-col cols="12" sm="12" md="6">
              Fuentes Multilaterales | Administración
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-btn
                color=""
                dark
                class="mb-2 float-right light-blue-502"
                @click="modalNuevo"
              >
                Registrar fuente multilateral
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="col-md-12 m-0 p-0">
            <v-divider></v-divider>
          </v-row>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-data-table
            class="elevation-0"
            :headers="headers"
            :items="fuentesBilaterales"
            :search="filtro"
            :loading="tableLoadingFuentes"
            :header-props="{ 
                sortByText: 'Ordenar por'
            }"
            no-data-text="No se encontraron registros"
            loading-text="Cargando..."
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              'items-per-page-text': 'Registros por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <!-- v-container, v-col and v-row are just for decoration purposes. -->
              <v-container fluid>
                <v-row>
                  <v-col cols="6" md="6" sm="6" xs="6">
                    <v-text-field
                      autocomplete="off"
                      class=""
                      dense
                      filled
                      color="blue-grey lighten-2"
                      v-model="filtro"
                      label="Nombre de la fuente bilateral"
                      maxlength="200"
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="6" md="6" sm="6" xs="6">
                    <v-select
                      v-model="estado"
                      :items="estados"
                      :loading="false"
                      dense
                      filled
                      label="Estado"
                      :no-data-text="'No existen estados registrados'"
                      menu-props="auto"
                      return-object
                    ></v-select>
                  </v-col>
  
                  <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                    <v-btn
                      class="ma-0 white--text d-none"
                      medium
                      color="light-blue darken-2"
                    >
                      <v-icon left>mdi-magnify</v-icon> Buscar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </template>
  
            <template v-slot:item="{ item }">
              <tr>
                <!-- <td>{{ item.id }}</td> -->
                <td>{{ item.nombreFuenteMultilateral }}</td>
                <td>{{ item.representanteResidente }}</td>
                <td>{{ item.fechaInicioActividades | formatDate }}</td>
           
               <!-- <td>
                  {{
                    item.nombreMaximaAutoridad != null
                      ? item.nombreMaximaAutoridad
                      : ""
                  }}
                </td>-->
                <td>
                  <v-chip
                    class="ma-2 font-weight-medium"
                    label
                    :color="
                      item.estadosId === 1
                        ? 'blue-grey lighten-4'
                        : item.estadosId === 2
                        ? 'blue lighten-4'
                        : item.estadosId === 6
                        ? 'cyan lighten-4'
                        : item.estadosId === 7
                        ? 'deep-orange lighten-4'
                        : 'pink lighten-4'
                    "
                    :text-color="
                      item.estadosId === 1
                        ? 'blue-grey lighten-1'
                        : item.estadosId === 2
                        ? 'blue lighten-1'
                        : item.estadosId === 6
                        ? 'cyan darken-1'
                        : item.estadosId === 7
                        ? 'deep-orange lighten-1'
                        : 'pink darken-1'
                    "
                    small
                  >
                    {{ item.estado }}
                  </v-chip>
                </td>
                <td>
                  <v-btn
                    class="
                      ma-2
                      btn-bg-light
                      blue-grey--text
                      lighten-2--text
                      font-weight-medium
                      text-capitalize
                    "
                    small
                    depressed
                    color="blue-grey lighten-5"
                    @click="obtenerDatosItem(item.id)"
                  >
                    <v-icon left>mdi-pencil</v-icon> Actualizar
                  </v-btn>                
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <!-- <template v-slot:html>
              {{ code4.html }}
            </template>
            <template v-slot:js>
              {{ code4.js }}
            </template> -->
      </v-card>
  
      <!--begin:: dialog suspension -->
      <v-dialog v-model="dialogSuspension" width="500" transition="scroll-y-transition">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Suspensión del proyecto
          </v-card-title>
  
          <v-card-text>
            <v-row>
              <v-col sm="12">
                Está seguro de suspender el proyecto {{ proyectoSeleccionado }}
              </v-col>
            </v-row>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> 
              Continuar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--end: dialog-->
  
      <!--begin:: modal registro-->
      <v-dialog
        v-model="dialogRegistro"
        max-width="1200"
        transition="scroll-y-transition"
        persistent
        scrollable
      >
        <v-card tile>
          <v-card-title>
            {{ accion === 1 ? `Registrar` : `Actualizar` }}  Fuente Multilateral
  
            <v-spacer></v-spacer>
            <v-btn
              icon
              :disabled="btnRegistroLoading"
              @click="dialogRegistro = false || resetForm()"
              class="float-right"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="d-none"></v-divider>
  
          <v-card-text class="pl-8 pr-8 pt-5">
            <v-form
              ref="form"
              v-on:submit.prevent="registrarItem"
              v-model="validForm"
            >

              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.nombreFuenteMultilateral"
                    label="Nombre de la fuente multilateral"
                    :rules="[
                      required('nombre de la fuente multilateral'),
                      minLength('nombre de la fuente multilateral', 3),
                      maxLength('nombre de la fuente multilateral', 100)
                    ]"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.paisSedeCentral"
                    label="Nombre del país sede central"
                    :rules="[
                      required('nombre del país sede central'),
                      minLength('nombre del país sede central', 3),
                      maxLength('nombre del país sede central', 100)
                    ]"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-textarea
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.paisesSedesRegionales"
                    label="Nombre de los países sedes regionales"
                    rows="4"
                    :rules="[
                      required('nombre de los países sedes regionales'),
                      minLength('nombre de los países sedes regionales', 3),
                      maxLength('nombre de los países sedes regionales', 100)
                    ]"
                    maxlength="100"
                  ></v-textarea>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-textarea
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.paisesMiembros"
                    label="Nombre de los países miembros"
                    rows="4"
                    :rules="[
                      required('nombre de los países miembros'),
                      minLength('nombre de los países miembros', 3),
                      maxLength('nombre de los países miembros', 100)
                    ]"
                    maxlength="100"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.nombreRepresentanteInstitucion"
                    label="Nombre del representante de la institución"
                    :rules="[
                      required('nombre del representante de la institución'),
                      minLength('nombre del representante de la institución', 3),
                      maxLength('nombre del representante de la institución', 100)
                    ]"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.presidenteEjecutivo"
                    label="Nombre del presidente ejecutivo"
                    :rules="[
                      required('nombre del presidente ejecutivo'),
                      minLength('nombre del presidente ejecutivo', 3),
                      maxLength('nombre del presidente ejecutivo', 100)
                    ]"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.representanteResidente"
                    label="Nombre del representante residente"
                    :rules="[
                      required('nombre del representante residente'),
                      minLength('nombre del representante residente', 3),
                      maxLength('nombre del representante residente', 100)
                    ]"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.funcionarioEnlace"
                    label="Nombre del funcionario enlace"
                    :rules="[
                      required('nombre del funcionario enlace'),
                      minLength('nombre del funcionario enlace', 3),
                      maxLength('nombre del funcionario enlace', 100)
                    ]"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
              </v-row>

              
              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-textarea
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.mision"
                    label="Misión"
                    rows="4"
                    :rules="[
                      required('misión'),
                      minLength('misión', 3),
                      maxLength('misión', 2000)
                    ]"
                    maxlength="2000"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-textarea
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.vision"
                    label="Visión"
                    rows="4"
                    :rules="[
                      required('visión'),
                      minLength('visión', 3),
                      maxLength('visión', 2000)
                    ]"
                    maxlength="2000"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-textarea
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.metasAccion"
                    label="Metas acción"
                    rows="4"
                    :rules="[
                      required('metas acción'),
                      minLength('metas acción', 3),
                      maxLength('metas acción', 2000)
                    ]"
                    maxlength="2000"
                  ></v-textarea>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-textarea
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.fines"
                    label="Fines"
                    rows="4"
                    :rules="[
                      required('fines'),
                      minLength('fines', 3),
                      maxLength('fines', 2000)
                    ]"
                    maxlength="2000"
                  ></v-textarea>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-textarea
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.funciones"
                    label="Funciones"
                    rows="4"
                    :rules="[
                      required('funciones'),
                      minLength('funciones', 3),
                      maxLength('funciones', 2000)
                    ]"
                    maxlength="2000"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-textarea
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.ejesTransversales"
                    label="Ejes transversales"
                    rows="4"
                    :rules="[
                      required('ejes transversales'),
                      minLength('ejes transversales', 3),
                      maxLength('ejes transversales', 2000)
                    ]"
                    maxlength="2000"
                  ></v-textarea>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.direccion"
                    label="Dirección"
                    :rules="[
                      required('dirección'),
                      minLength('dirección', 3),
                      maxLength('dirección', 100)
                    ]"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.sitioWeb"
                    label="Sitio web"
                    :rules="[
                      required('sitio web'),
                      minLength('sitio web', 3),
                      maxLength('sitio web', 100)
                    ]"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.telefonoContacto"
                    label="Teléfono de contacto"
                    :rules="[
                      required('Teléfono de contacto'),
                      minLength('Teléfono de contacto', 8),
                      maxLength('Teléfono de contacto', 15)
                    ]"
                    maxlength="15"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                    <v-menu
                        ref="menuFechaInicioActividades"
                        v-model="menuFechaInicioActividades"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            dense
                            filled
                            color="blue-grey lighten-2"
                            v-model="computedFechaInicioActividades"
                            label="Fecha de inicio de actividades"
                            hint="DD/MM/AAAA"
                            persistent-hint
                            append-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            :rules="[
                                required('Fecha de inicio de actividades'),
                            ]"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="dateFechaInicioActividades"
                        no-title
                        @input="menuFechaInicioActividades = false"
                        locale="es"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey lighten-5"
              elevation="0"
              class="mb-2 float-right grey lighten-5"
              @click="
                dialogRegistro = false;
                resetForm();
              "
              :disabled="btnRegistroLoading"
            >
              Cancelar
            </v-btn>
            <!--:disabled="!validDocForm" type="submit"-->
            <v-btn
              color="light-blue-502"
              class="white--text mb-2 float-right"
              type="submit"
              :elevation="0"
              :disabled="!validForm"
              :loading="btnRegistroLoading"
              @click="registrarItem"
            >
              {{btnRegistroText}}
            </v-btn>
          </v-card-actions>
  
          <div style="flex: 1 1 auto"></div>
        </v-card>
      </v-dialog>
  
      <!--end:: modal registro-->
  
      <!--Dialog loader -->
      <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
      ></DialogLoader>
      <!---->
  
      <!--Inicio:: Snack alert-->
      <SnackAlert ref="snackalert"></SnackAlert>
      <!-- Fin:: Snack alert-->
    </div>
  </template>
  
  <script>
    import Vue from 'vue';
    import moment from "moment";
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import DialogLoader from "@/view/content/DialogLoader";
  
  import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
  
  import validations from "@/core/untils/validations.js";
  
  import { OBTENER_FUENTES_MULTILATERALES, ACTUALIZAR_FUENTE_MULTILATERAL, REGISTRAR_FUENTE_MULTILATERAL, OBTENER_FUENTE_MULTILATERAL } from "@/core/services/store/catalogos/fuentesmultilaterales/fuentemultilateral.module";
  import { OBTENER_PAISES } from "@/core/services/store/catalogos/paises/pais.module";

  moment.locale('es');
    Vue.filter("formatDate", function(value) {
        if (value) {
            return moment(String(value)).format("DD/MM/YYYY");
        }
    });
  
  export default {
    name: "AdminFuentesMultilaterales",
    data() {
      return {
        dateFechaInicioActividades: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menuFechaInicioActividades: false,
        menuFechaFinPeriodo: false,
        validForm: false,
        ddRolesLoading: false,
        dialogRegistro: false,
        dialogLoaderVisible:false,
        dialogLoaderText: '',
        tableLoading: false,
        dialogSuspension: false,
        switchItemEstado: true,
        proyectoSeleccionado: "",
        filtro: "",
        dialog: false,
        paises: [],
        ddPaisesLoading:false,
        modalTitle: "Registrar Fuente Bilateral",
        accion: 1,
        items: [],
        fuentesBilaterales: [],
        btnRegistroText: "Guardar",
        btnRegistroLoading: false,
        estadoProyecto: "",
        datosItem: {
            nombreFuenteMultilateral: "",
            paisSedeCentral: "",
            paisesSedesRegionales: "",
            paisesMiembros: "",
            nombreRepresentanteInstitucion: "",
            presidenteEjecutivo: "",
            representanteResidente: "",
            funcionarioEnlace: "",
            mision: "",
            vision: "",
            metasAccion: "",
            fines: "",
            funciones: "",
            ejesTransversales: "",
            direccion: "",
            sitioWeb: "",
            telefonoContacto: "",
            estadosId: 1,
            usuarioCreacion: "admin",
        },
        estado: "",
        estados: [
          { text: "Todos", value: null },
          { value: 1, text: "Activo" },
          { value: 2, text: "Inactivo" }
        ],
        ...validations,
       
  
        
      };
    },
    components: {
      DialogLoader,
      SnackAlert
    },
    methods: {
      filtroEstado(value) {
        // If this filter has no value we just skip the entire filter.
        if (!this.estado.value) {
          return true;
        }
        // Check if the current loop value (The calories value)
        // equals to the selected value at the <v-select>.
        return value === this.estado.text;
      },
  
      //Obtener las fuentesBilaterales
      async obtenerFuentesBilaterales() {
        this.fuentesBilaterales = [];
        this.tableLoadingFuentes = true;
  
        await this.$store
          .dispatch(OBTENER_FUENTES_MULTILATERALES, { estadoId: 1, paisId: 0})
          .then(res => {
              if(res.status===200){
                  this.fuentesBilaterales = res.data;
              }
              this.tableLoadingFuentes = false;
          })
          .catch(() => {
            this.tableLoadingFuentes = false;
            this.fuentesBilaterales = [];
          });
      },
  
      // Obtener países
      async obtenerPaises() {
        this.paises = [];
        this.ddPaisesLoading = true;
  
        await this.$store
          .dispatch(OBTENER_PAISES)
          .then(res => {
              if(res.status===200){
  
                  this.paises = res.data;
              }
            this.tableLoading = false;
          })
          .catch(() => {
            this.paises=[];
            this.ddPaisesLoading = false;
          });
      },
  
      
  
      modalNuevo() {
        this.accion = 1;
        this.modalTitle = "Registrar unidad ejecutora";
        this.btnRegistroText = "Guardar";
        this.switchItemEstado = true;
        this.dialogRegistro = true;
      },
  
  
  
      ///Registror unidad ejecutora
      async registrarItem() {
        this.btnRegistroLoading = true;
  
        //!Asignar el estado a datos empresa segun lo seleccionado en el swithc del formulario
        this.switchItemEstado
          ? (this.datosItem.estadosId = 1)
          : (this.datosItem.estadosId = 2);
  
        this.datosItem.usuarioCreacion= "test";

        this.datosItem.fechaInicioActividades = moment(this.computedFechaInicioActividades, "DD/MM/YYYY").format("YYYY-MM-DD");
        
  
        let dispatch = REGISTRAR_FUENTE_MULTILATERAL;
  
        if(this.accion===2){
          dispatch = ACTUALIZAR_FUENTE_MULTILATERAL;
        }
  
  
        //?Si accion es 1 se realiza el registro
        
          await this.$store
            .dispatch(dispatch, this.datosItem)
            .then(res => {
              this.btnLoading = false;
              //this.dialogLoaderVisible = false;
              if (res.status === 200) {
                this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);
                this.obtenerFuentesBilaterales();
                this.dialogRegistro = false;
                this.resetForm();
              } else {
                this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
              }
              this.btnRegistroLoading = false;
            })
            .catch(error => {
              this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro. ${error}`);
            });
      },
  
  
  
      //!Obtener datos de un item especifico
      async obtenerDatosItem(Id) {
       
        this.dialogLoaderVisible = true;
        this.dialogLoaderText = "Obteniendo información...";
        this.dialogTitulo = `Actualizar ${this.itemName}`;
       
        this.btnRegistroText = `Actualizar`;
        this.datosItem={};
        await this.$store
          .dispatch(OBTENER_FUENTE_MULTILATERAL, Id)
          .then(res => {
            //console.log(res);
            if (res.status === 200) {
              this.datosItem = res.data;

              this.dateFechaInicioActividades =   moment(this.datosItem.fechaInicioActividades, "YYYY-MM-DD").format("YYYY-MM-DD");
              this.datosItem.estadosId === 1
                ? (this.switchItemEstado = true)
                : (this.switchItemEstado = false);
              this.accion = 2;
               this.modalTitle = `Actualizar unidad ejecutora ${this.datosItem.nombreUnidadEjecutora}`
              this.dialogRegistro = true;
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
              );
            }
            this.dialogLoaderVisible = false;
          })
          .catch(error => {
            console.log(error)
            this.dialogLoaderVisible = false;
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
            );
            
          });
      },
  
      cerrarModalRegistro() {
        //this.dialogRegistro = false;
      },
  
      //Mostrar el dialogo para la suspension
      mostrarDialogSuspension(nombreProyecto) {
        this.proyectoSeleccionado = nombreProyecto;
        this.dialogSuspension = true;
      },
  
      resetForm() {
  
        this.datosItem = {
            nombreFuenteMultilateral: "",
            paisSedeCentral: "",
            paisesSedesRegionales: "",
            paisesMiembros: "",
            nombreRepresentanteInstitucion: "",
            presidenteEjecutivo: "",
            representanteResidente: "",
            funcionarioEnlace: "",
            mision: "",
            vision: "",
            metasAccion: "",
            fines: "",
            funciones: "",
            ejesTransversales: "",
            direccion: "",
            sitioWeb: "",
            telefonoContacto: "",
            estadosId: 1,
            usuarioCreacion: "admin",
        }
  
      },
      formatDatePicker (date) {
            //console.log(date)
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
    
    },
    created(){
        this.obtenerFuentesBilaterales();
        this.obtenerPaises();
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Inicio", route: "dashboard" },
        { title: "Administracion" },
        { title: "Agencias Bilaterales" }
      ]);
    }, 
  
    computed: {

        computedFechaInicioActividades: {
            get(){
                //console.log("asdf")
                return this.formatDatePicker(this.dateFechaInicioActividades)
            },
            set(newValue){
                //console.log(newValue)
                return this.formatDatePicker(moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD"))
            }
        },




      headers(){
       return [
         /*  {
            text: "Id",
            align: "start",
            sortable: false,
            value: "codigo"
          }, */
          {
            text: "Nombre Fuente Multilateral",
            align: "start",
            sortable: true,
            value: "nombreFuenteMultilateral"
          },
          {
            text: "Representante",
            align: "start",
            sortable: true,
            value: "representanteResidente"
          },
          {
            text: "Fecha Inicio de Actividades",
            align: "start",
            sortable: true,
            value: "fechaInicioActividades"
          },
          /*{
            text: "Fecha Fin del Periodo",
            align: "start",
            sortable: true,
            value: "fechaFinPeriodo"
          },
          {
            text: "Nivel de ingreso",
            align: "start",
            sortable: true,
            value: "nivelIngreso"
          },*/
          {
            text: "Estado",
            align: "start",
            sortable: true,
            value: "estado",
            filter: this.filtroEstado
          },
          {
            text: "Acciones",
            align: "start",
            sortable: false,
            value: ""
          }
        ]
      }
    }
  };
  </script>
  